<template>
  <v-dialog
    ref="dialog"
    v-model="isDialogOpened"
    v-resize="updatePlayerDimensions"
    fullscreen
    hide-overlay
    transition="none"
    class="snapshot-player-dialog"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <slot name="custom-button">
          <v-btn class="left_nav_color" fab>
            <v-icon> fas fa-play </v-icon>
          </v-btn>
        </slot>
      </span>
    </template>
    <v-card v-if="isDialogOpened" class="previewable-thumbnail">
      <v-card-title class="pa-0">
        <v-toolbar
          class="custom-toolbar"
          :height="$vuetify.breakpoint.smAndDown ? 70 : 45"
        >
          <v-toolbar-title
            :class="{
              'd-flex flex-column w-90': $vuetify.breakpoint.smAndDown,
            }"
          >
            <span
              class="subtitle-2"
              :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
            >
              {{ title }}
            </span>
            <span v-if="!$vuetify.breakpoint.smAndDown" class="ml-3">|</span>

            <v-btn :href="recordingsUrl" class="background" target="_blank">
              {{ $t("actions.open_in_recordings") }}
              <v-icon right small> fa-external-link-alt </v-icon>
            </v-btn>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="close-icon t-0 r-3"
            color="primary"
            icon
            dark
            large
            @click="isDialogOpened = false"
          >
            <v-icon>fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text
        ref="playerContainer"
        class="previewable-thumbnail__content pa-0"
      >
        <EvercamPlayer
          :key="selectedCameraExid"
          :camera="selectedCamera"
          :start="previewInterval.start"
          :end="previewInterval.end"
          :is-playing="isPlaying"
          :height="playerDimensions.height"
          :width="playerDimensions.width"
          :snapshot-interval="previewInterval"
          :initial-snapshot-quality="showSnapshotQuality ? '720' : 'auto'"
          :show-snapshot-quality="showSnapshotQuality"
          :auth-token="authToken"
          :selected-timestamp="timestamp"
          fit-container
          :nvr-config="edgeStreamingConfig"
          :aspect-ratio="0"
          class="previewable-thumbnail__player"
          :class="{ 'h-100': $device.isMobile }"
          :is-edge-video="isEdgeVideo"
          :show-bif="false"
          v-bind="$attrs"
          v-on="$listeners"
          @update-playback="isPlaying = $event"
        >
          <template #top-right>
            <TimelinePlayerCameraSelector
              v-if="defaultSelectableCameras.length"
              :token="authToken"
              :cameras="defaultSelectableCameras"
              :selected-camera="selectedCamera"
              :target-timestamp="timestamp"
              :item-width="120"
              class="e-absolute e-top-0"
              @change="(c) => (selectedCamera = c)"
            />
          </template>
        </EvercamPlayer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue"
import type { PropType } from "vue"
import type { Camera, Project, CameraExid } from "@evercam/shared/types"
import { CameraFeatureFlag, NvrModel } from "@evercam/shared/types"
import TimelinePlayerCameraSelector from "@evercam/shared/components/timelinePlayer/TimelinePlayerCameraSelector"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer.vue"

export default Vue.extend({
  name: "SnapshotPreview",
  components: {
    EvercamPlayer,
    TimelinePlayerCameraSelector,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      default: () => ({} as Camera),
    },
    project: {
      type: Object as PropType<Project>,
      default: () => ({} as Project),
    },
    numberOfFrames: {
      type: Number,
      default: () => 20,
    },
    title: {
      type: String,
      default: () => "",
    },
    timestamp: {
      type: String,
      default: () => null,
    },
    showSnapshotQuality: {
      type: Boolean,
      default: false,
    },
    authToken: {
      type: String,
      default: "",
    },
    videoDuration: {
      type: Number,
      default: 2,
    },
    selectableCameras: {
      type: Array as PropType<Camera[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isDialogOpened: false,
      selectedCamera: this.camera,
      playerDimensions: {} as { width: string; height: string },
      isPlaying: false,
    }
  },
  computed: {
    isEdgeVideo(): boolean {
      return (
        this.selectedCamera?.nvrModel === NvrModel.ExNvr &&
        this.selectedCamera?.featureFlags?.includes(CameraFeatureFlag.EdgeVideo)
      )
    },
    selectedCameraExid(): CameraExid {
      return this.selectedCamera.exid || this.selectedCamera.id
    },
    defaultSelectableCameras(): Camera[] {
      if (this.selectableCameras.length > 0) {
        return this.selectableCameras
      }

      return (this.project.cameras as Camera[]).filter(
        (c) =>
          c?.featureFlags?.includes(CameraFeatureFlag.ANPR) ||
          c?.featureFlags?.includes(CameraFeatureFlag.GateReport)
      )
    },
    intervalDuration(): number {
      if (this.isEdgeVideo) {
        return this.videoDuration
      }

      const framesPerMinute = this.camera?.cloudRecordings?.frequency ?? 12

      return this.numberOfFrames / framesPerMinute
    },
    previewInterval(): { start: string; end: string } {
      const start = this.$moment
        .utc(this.timestamp)
        .subtract({ minutes: this.intervalDuration / 2 })
        .format()
      const end = this.$moment
        .utc(this.timestamp)
        .add({ minutes: this.intervalDuration / 2 })
        .format()

      return {
        start: start,
        end: end,
      }
    },
    edgeStreamingConfig(): Record<string, string> {
      const apiUrl = `https://${this.selectedCamera?.nvrHost}:${this.selectedCamera?.nvrHttpPort}`

      return {
        apiUrl,
        deviceId: this.selectedCamera?.nvrDeviceId,
        streamingUrl: `${apiUrl}${
          this.selectedCamera?.streamEndpoint ||
          this.selectedCamera?.nvrStreamingEndpoint
        }`,
        snapshotUrl: `${apiUrl}${
          this.selectedCamera?.nvrPictureEndpoint ||
          this.selectedCamera?.nvrSnapshotEndpoint
        }`,
      }
    },
    recordingsUrl(): string {
      const recordingsTimestamp = this.$moment
        .utc(this.timestamp)
        .tz(this.project?.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      const baseUrl =
        this.$config.public.dashURL || `${window.location.origin}/v2`

      return `${baseUrl}/projects/${this.project?.exid}/${this.selectedCameraExid}/recordings/snapshots/${recordingsTimestamp}`
    },
  },
  watch: {
    camera: {
      async handler(newVal) {
        this.selectedCamera = newVal
      },
      immediate: true,
    },
    isDialogOpened: {
      async handler(value) {
        if (value) {
          this.updatePlayerDimensions()
        }
      },
      immediate: true,
    },
  },

  methods: {
    updatePlayerDimensions() {
      if (!this.isDialogOpened) {
        return
      }
      const main = this.$refs.playerContainer as HTMLDivElement
      if (!main) {
        this.$setTimeout(() => {
          this.updatePlayerDimensions()
        }, 100)

        return
      }
      const containerTop = main.getBoundingClientRect().top
      this.playerDimensions = {
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight - containerTop}px`,
      }
    },
  },
})
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
.previewable-thumbnail {
  border-radius: 0;
  background: transparent;
  &__content {
    position: relative;
    .timeline-container {
      padding: 1em 0;
      &:hover {
        .timeline {
          padding: 0.3em;
        }
      }
      .timeline {
        height: 6px;
        opacity: 0.8;
      }
    }
  }
  &__player {
    .player {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

::v-deep .custom-toolbar .v-toolbar__content {
  line-height: 45px !important;
}
.snapshot-player-dialog {
  z-index: 999 !important;
}
</style>
